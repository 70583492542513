(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userCloudStorageDialogController", userCloudStorageDialogController);

	function userCloudStorageDialogController($scope, $mdDialog, $http, errorHandling, connection, allowDelete) {
		$scope.allowDelete = allowDelete;
		$scope.name = connection.friendlyName;
		$scope.type = connection.type;

		if ($scope.type === 10) {
			$scope.serverAddress = connection.url;
			$scope.userName = connection.userName;
			$scope.password = connection.password;
		}

		$scope.save = function () {
			var retParams = {
				deleteItem: false,
				name: $scope.name,
				url: $scope.serverAddress,
				userName: $scope.userName,
				password: $scope.password
			};

			if ($scope.type !== 10) {
				$mdDialog.hide(retParams);
				return;
			}

			var params = JSON.stringify({
				url: $scope.serverAddress,
				userName: $scope.userName,
				password: $scope.password
			});

			$http.post("~/api/v1/settings/owncloud-test", params)
				.then(
					function (result) {
						if (!result.data.success) {
							errorHandling.report(result.data.message);
							return;
						}
						
						$mdDialog.hide(retParams);
					},
					errorHandling.report);
		};

		$scope.delete = function () {
			$mdDialog.hide({ deleteItem: true });
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	}

})();
